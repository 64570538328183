// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-contacts-js": () => import("./../../../src/pages/contacts.js" /* webpackChunkName: "component---src-pages-contacts-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-merchant-terms-and-conditions-js": () => import("./../../../src/pages/merchant-terms-and-conditions.js" /* webpackChunkName: "component---src-pages-merchant-terms-and-conditions-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-sell-with-us-js": () => import("./../../../src/pages/sell-with-us.js" /* webpackChunkName: "component---src-pages-sell-with-us-js" */),
  "component---src-pages-signature-js": () => import("./../../../src/pages/signature.js" /* webpackChunkName: "component---src-pages-signature-js" */),
  "component---src-pages-solutions-cafes-and-restaurants-js": () => import("./../../../src/pages/solutions/cafes-and-restaurants.js" /* webpackChunkName: "component---src-pages-solutions-cafes-and-restaurants-js" */),
  "component---src-pages-solutions-offices-js": () => import("./../../../src/pages/solutions/offices.js" /* webpackChunkName: "component---src-pages-solutions-offices-js" */),
  "component---src-pages-solutions-supermarkets-js": () => import("./../../../src/pages/solutions/supermarkets.js" /* webpackChunkName: "component---src-pages-solutions-supermarkets-js" */),
  "component---src-pages-terms-and-conditions-js": () => import("./../../../src/pages/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-js" */)
}

